<template>
  <v-app-bar app light>
    <v-app-bar-nav-icon
      @click.stop="$root.$emit('open-close-nav')"
    ></v-app-bar-nav-icon>
    <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
      <span class="hidden-sm-and-down"></span>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <div class="tw-flex tw-items-center">
      <div class="tw-border-r-2 tw-border-gray-300 tw-pr-4">
        <acp-notifications></acp-notifications>
      </div>
      <app-avatar> </app-avatar>
    </div>
  </v-app-bar>
</template>

<script>
export default {
  components: {
    appAvatar: () => import('@/components/navigation/AvatarMenu'),
    acpNotifications: () => import('@/components/notifications/Notifications'),
  },
}
</script>
